<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Filialen</h4>
                            <p>Liste aller Filialen</p>
                        </div>

                        <div class="card-tools">
                            <button type="button" class="btn btn-default mr-1" @click="loadStores">
                                <i class="fas fa-sync"></i>
                            </button>
                            <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('stores.create')">
                                <i class="fas fa-plus-circle"></i> Neue Filiale
                            </button>
                            <!-- <a href="#" class="btn btn-primary pull-right">Create User</a> -->
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row class="mb-3">
                            <b-col md="3">
                                <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table striped hover outlined :fields="fields" :items="stores" :filter="filter" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                                    <template #cell(actions)="row">
                                        <b-button size="sm" @click="editModal(row.item)" class="mr-1" variant="warning" v-if="$auth.check('stores.edit')"><i class="fas fa-edit"></i></b-button>
                                        <b-button size="sm" @click="deleteStore(row.item.id)" variant="danger" v-if="$auth.check('stores.destroy')"><i class="fas fa-trash"></i></b-button>
                                    </template>
                                </b-table>
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>

        <b-modal :no-enforce-focus="true" id="storeModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, form.id)">
                <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
                    <div class="row">
                        <div class="col-md-6">

                            <div class="form-group">
                                <label for="name" class="control-label">Name*</label>
                                <input v-model="form.name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('name')}">
                                <has-error :form="form" field="name"></has-error>
                            </div>
                        </div>

                        <div class="col-md-6">

                            <div class="form-group">
                                <label for="email" class="control-label">E-Mail</label>
                                <input v-model="form.email" type="email" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('email')}">
                                <has-error :form="form" field="email"></has-error>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="warehouse_id" class="control-label">Lager*</label>
                                    <select name="warehouse_id" id="warehouse_id" class="form-control form-control-sm" v-model="form.warehouse_id" :class="{'is-invalid': form.errors.has('warehouse_id')}">
                                        <option v-for="warehouse in warehouses" :key="warehouse.id" v-bind:value="warehouse.id">{{warehouse.name}}</option>
                                    </select>
                                    <has-error :form="form" field="warehouse_id"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="name" class="control-label">Beschreibung</label>
                                <editor
                                v-bind:api-key="this.$tinyApiKey"
                                :init="{
                                    height: 250,
                                    menubar: true,
                                    plugins: [
                                    'code advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | code | help'
                                }"
                                v-model="form.description"
                                :class="{'is-invalid': form.errors.has('description')}"
                                />
                                <has-error :form="form" field="description"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="business_hours_description">Kommentar zu den Öffnungszeiten</label>
                                <input type="text" class="form-control form-cotrol-sm" v-model="form.business_hours_description">
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="form-group">
                                            <label for="street" class="control-label">Straße*</label>
                                            <input v-model="form.street" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('street')}">
                                            <has-error :form="form" field="street"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="house_number" class="control-label">Nr.*</label>
                                            <input v-model="form.house_number" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('house_number')}">
                                            <has-error :form="form" field="house_number"></has-error>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="zipcode" class="control-label">PLZ*</label>
                                        <input v-model="form.zipcode" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('zipcode')}">
                                        <has-error :form="form" field="zipcode"></has-error>
                                    </div>

                                    <div class="col-md-8">
                                        <label for="city" class="control-label">Ort*</label>
                                        <input v-model="form.city" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('city')}">
                                        <has-error :form="form" field="city"></has-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="name" class="control-label">Lange Beschreibung</label>
                                <editor
                                            class="form-control"
                                            :class="{'is-invalid': form.errors.has('long_description')}"
                                            v-model="form.long_description"
                                            :init="editorConfig"
                                            />
                                <has-error :form="form" field="long_description"></has-error>
                            </div>
                        </div>
                    </div>
                </form>
            </b-modal>
    </b-container>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
// import ClassicEditor from '../../../assets/packages/ckeditor5/src/ckeditor';
import "tinymce/tinymce";
// import "./../../../assets/packages/tinymce-bootstrap";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/bootstrap";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/help";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/table";


export default {
    name: "settings.stores",
    title: "Filialen",
    data() {
        return{
            form: new window.Form({
                id: "",
                name: "",
                description: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                warehouse_id: "",
                business_hours_description: null,
                long_description: null,
                email: ""
            }),
            stores: [],
            warehouses: [],
            sortBy: "id",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            fields: [
                {key: "id", sortable: true},
                {key: "name", label: "Name", sortable: true},
                {key: "street", label: "Straße", sortable: true},
                {key: "house_number", label: "Nr.", sortable: true},
                {key: "zipcode", label: "PLZ", sortable: true},
                {key: "city", label: "Ort", sortable: true},
                {key: "actions", label: "Actions", tdClass: 'hls_action_column'},
            ],
            editMode: false,
            modalTitle: '',
            editorConfig: {
                a11y_advanced_options: true,
                height: 500,
                menubar: false,
                plugins: 'advlist autolink bootstrap code link image fullscreen lists charmap print preview help table',
                toolbar: [
                    'undo redo | bootstrap | fullscreen',
                    'cut copy paste | styleselect | alignleft aligncenter alignright alignjustify | bold italic | link image | code | help'
                ],
                contextmenu: 'link image table spellchecker | bootstrap',
                images_upload_handler: this.uploadImage,
                file_picker_types: 'image',
                bootstrapConfig: {
                    url: 'http://localhost:3000/tinymce-bootstrap-plugin',
                    iconFont: 'fontawesome5',
                    editorStyleFormats: {
                        textStyles: true,
                        blockStyles: true,
                        containerStyles: true,
                        responsive: ['xs', 'sm', 'md', 'lg'], // all the responsive options enabled
                        spacing: ['all', 'x', 'y', 'top', 'right', 'bottom', 'left']
                    },
                    elements: { // only the following elements will be added to the toolbar
                        btn: false,
                        icon: false,
                        image: false,
                        table: true,
                        template: true,
                        breadcrumb: false,
                        pagination: false,
                        badge: false,
                        alert: false,
                        card: false,
                        snippet: true
                    },
                    key: 'tAj3ykawOTzO195azIrI3+pNZJT8Qd4NiKUWc1iwhWMYCiVNl7mKcwWCRo/o89nM+2Q8OIMb8ndAjTt4Iy4SRmj1r+y3Cu3BdQhLqmphy68='
                },
                formats: { // replace the default Tinymce inline styles with Bootstrap css classes & appropriate tags
                    alignleft: {selector : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes : 'text-left'},
                    aligncenter: {selector : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes : 'text-center'},
                    alignright: {selector : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes : 'text-right'},
                    alignjustify: {selector : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes : 'text-justify'},
                    bold: {inline : 'strong'},
                    italic: {inline : 'em'},
                    underline: {inline : 'u'},
                    sup: {inline : 'sup'},
                    sub: {inline : 'sub'},
                    strikethrough: {inline : 'del'}
                },
                style_formats: [
                    { title: 'Headings', items: [
                        { title: 'Heading 1', format: 'h1' },
                        { title: 'Heading 2', format: 'h2' },
                        { title: 'Heading 3', format: 'h3' },
                        { title: 'Heading 4', format: 'h4' },
                        { title: 'Heading 5', format: 'h5' },
                        { title: 'Heading 6', format: 'h6' }
                    ]},
                    { title: 'Blocks', items: [
                        { title: 'Paragraph', format: 'p' },
                        { title: 'Blockquote', format: 'blockquote' },
                        { title: 'Div', block: 'div', wrapper: true }
                    ]},
                    { title: 'Containers', items: [
                        { title: 'Container fluid', block: 'div', classes: 'container-fluid', wrapper: true },
                        { title: 'Container', block: 'div', classes: 'container', wrapper: true },
                        { title: 'Section', block: 'section', wrapper: true },
                        { title: 'Article', block: 'article', wrapper: true }
                    ]}
                ],
                style_formats_merge: false, // if true, add TinyMce's default style formats to the user's style_formats,
                style_formats_autohide: true // hide the custom formats that don't match the selected element in the editor
            },
            url: process.env.VUE_APP_API_URL + 'images/upload',
            editor: Editor,
        }
    },

    methods: {
        uploadImage(blobInfo, success, failure, progress){
            var xhr, formData;

            xhr = new XMLHttpRequest();
            xhr.withCredentials = false;
            xhr.open('POST', this.url);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.$auth.token());

            xhr.upload.onprogress = function (e) {
                progress(e.loaded / e.total * 100);
            };

            xhr.onload = function() {
                var json;

                if (xhr.status === 403) {
                failure('HTTP Error: ' + xhr.status, { remove: true });
                return;
                }

                if (xhr.status < 200 || xhr.status >= 300) {
                failure('HTTP Error: ' + xhr.status);
                return;
                }

                json = JSON.parse(xhr.responseText);

                if (!json || typeof json.urls.default != 'string') {
                failure('Invalid JSON: ' + xhr.responseText);
                return;
                }

                success(json.urls.default);
            };

            xhr.onerror = function () {
                failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
            };

            formData = new FormData();
            formData.append('upload', blobInfo.blob(), blobInfo.filename());

            xhr.send(formData);
        },

        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editMode == true)
            {
                //Edit User
                this.editStore(id);
            }
            else
            {
                //Create User
                this.createStore();
            }
        },

        createModal() {
            this.editMode = false;
            this.modalTitle = "Neue Filiale anlegen";
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("storeModal");
        },

        editModal(Store) {
            this.editMode = true;
            this.modalTitle = "Filiale editieren";
            this.form.clear();
            this.form.reset();
            this.form.fill(Store);
            this.$bvModal.show("storeModal");

        },

        createStore() {
            this.$Progress.start();
            this.form
                .post("/stores")
                .then(() => {
                    this.$bvModal.hide("storeModal");
                    this.$swal({
                        icon: "success",
                        title: "Filiale wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadStores();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        editStore(id){
            this.$Progress.start();
            this.form
                .put("/stores/"+ id)
                .then(() => {
                    this.$bvModal.hide("storeModal");
                    this.$swal({
                        icon: "success",
                        title: "Filiale wurde editiert",
                    });
                    this.$Progress.finish();
                    this.loadStores();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        deleteStore(id){
            this.$swal({
                title: "Möchtest du die Filiale wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/stores/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Filiale erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadStores();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        async loadStores(){
            this.$Progress.start();
            await this.axios
                .get("/stores")
                .then((response) => {
                    this.stores = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                }); 
        },

        async loadWarehouses(){
            this.$Progress.start();
            await this.axios
                .get("/warehouses")
                .then((response) => {
                    this.warehouses = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                }); 
        }
    },

    created() {
        this.loadStores();
        this.loadWarehouses();
    },

    computed: {
        tableData() {
            return this.stores || [];
        },

        rows() {
            return this.stores.length;
        },
    },

}

</script>

<style>

</style>